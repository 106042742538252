import React from 'react';

import AppButton from '../AppButton';
import AppInput from '../AppInput';
import AssessmentsService from '../../services/AssessmentsService';
import AppSlideOver from '../AppSlideOver';
import AppSwitch from '../AppSwitch';
import AppWarningMessage from '../AppWarningMessage';
import NotificationService from '../../services/NotificationService';
import AppRIIcon from '../AppRIIcon';
import { useUser } from '../../hooks/UserProvider';
import LicenseService from '../../services/LicenseService';

const AddLicensesModal = ({ isOpen, closeModal, assessment }) => {
	const [addAdjustment, setAddAdjustment] = React.useState(0);
	const [removeAdjustment, setRemoveAdjustment] = React.useState(0);
	const [isRemoving, setIsRemoving] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const { setUser, user } = useUser();
	const [breakdownCounts, setBreakdownCounts] = React.useState({
		assigned: 0,
		completed: 0,
		available: 0,
		shared: 0,
		duplicated: 0,
	});
	const { assigned, completed, available, shared, duplicated } = breakdownCounts;
	const licenseCounts = [
		{ name: 'Total licenses available', value: user.licenses },
		{ name: 'Assigned to this group', value: assigned },
		{ name: 'Used by this group', value: completed },
		{ name: 'Still available for use by this group', value: available },
	];
	const otherAssessmentCounts = [
		{ name: 'Shared into this group', value: shared },
		{ name: 'Duplicated into this group', value: duplicated },
		{ name: 'Total Assessments in this group', value: parseInt(assessment.licenses) },
	];

	React.useEffect(() => {
		if (!isOpen) {
			setAddAdjustment(0);
			setRemoveAdjustment(0);
			setIsRemoving(false);
			return;
		}
		LicenseService.breakdownLicenseCounts(assessment.id).then((data) => {
			setBreakdownCounts({
				...data,
				assigned: assessment.licenses - data.shared - data.duplicated,
				available: assessment.licenses - data.completed - data.shared - data.duplicated,
			});
		});
	}, [isOpen]);
	const hasLicenses = React.useMemo(() => user.licenses !== 0, [user.licenses]);
	const currentLicenses = React.useMemo(() => parseInt(assessment.licenses), [assessment.licenses]);
	const canRemove = React.useMemo(
		() => currentLicenses - removeAdjustment >= Math.max(parseInt(assessment.num_scores), 2),
		[assessment.num_scores, currentLicenses, removeAdjustment]
	);
	const _addLicenses = () => {
		const payload = {
			assessment_id: assessment.id,
			num_of_licenses: isRemoving ? -removeAdjustment : addAdjustment,
		};
		setLoading(true);
		let promise;
		if (payload.num_of_licenses !== parseInt(assessment.num_scores) - parseInt(assessment.licenses)) {
			promise = AssessmentsService.addLicenses(payload);
		} else {
			promise = AssessmentsService.close(payload);
		}
		promise
			.then(() => {
				setUser((u) => ({ ...u, licenses: u.licenses - parseInt(payload.num_of_licenses) }));
				closeModal({
					saved: true,
					assessment: {
						...assessment,
						licenses: parseInt(assessment.licenses) + parseInt(payload.num_of_licenses),
						complete: 0,
						completed: null,
					},
				});
			})
			.catch((e) => {
				NotificationService.error('Error', e.message);
			})
			.finally(() => setLoading(false));
	};

	return (
		<AppSlideOver id={'app-add-licenses-modal-id'} isOpen={isOpen} closeModal={() => closeModal({})}>
			<div className='share-assessment-slide-over'>
				{!hasLicenses && (
					<AppWarningMessage
						showIcon={false}
						title={'You have no licenses on your account'}
						message={
							'It looks like you have run out of licenses. You can always remove licenses from this group to add them into your account.'
						}
					/>
				)}
				<div className='share-assessment-info mb-6'>
					<p className='text-lg text-primary font-semibold leading-5 mb-2'>Adjust Licenses</p>
					<p className='slide-over-text'>Adjust your group licenses</p>
				</div>
				<p className={'text-primary text-16 font-medium mb-3'}>My licenses</p>
				<div className='flex flex-col gap-2'>
					{licenseCounts.map(({ name, value }, index) => {
						return (
							<div key={index} className='flex flex-1'>
								<p className='slide-over-text flex flex-1'>{name}</p>
								<p className='slide-over-text semibold'>{value}</p>
							</div>
						);
					})}
				</div>
				<hr className={'text-gray-400 my-6'} />
				<p className={'text-primary text-16 font-medium mb-3'}>Other Assessments</p>
				<div className='flex flex-col gap-2'>
					{otherAssessmentCounts.map(({ name, value }, index) => {
						return (
							<div key={index} className='flex flex-1'>
								<p className='slide-over-text flex flex-1'>{name}</p>
								<p className='slide-over-text semibold'>{value}</p>
							</div>
						);
					})}
				</div>
				<hr className={'text-gray-400 my-6'} />
				<p className={'text-primary text-16 mt-8 font-medium mb-4'}>Assign licenses to this group</p>
				<div className='flex flex-1 items-center'>
					<p className='slide-over-text flex flex-1'>Total licenses</p>
					<div className={'w-20'}>
						<AppInput
							min={1}
							max={user.licenses}
							disabled={isRemoving || !hasLicenses}
							containerClassName={'no-margin'}
							type={'number'}
							value={addAdjustment}
							onChange={(e) => setAddAdjustment(Math.min(e.target.value, user.licenses))}
						/>
					</div>
				</div>
				<hr className={'text-gray-400 my-4'} />

				<div className='flex flex-1 mt-4'>
					<p className='text-primary text-16 font-semibold leading-5 mb-2'>
						Do you want to remove licenses from this group?
					</p>
					<AppSwitch
						value={isRemoving}
						onChange={(value) => {
							setIsRemoving(value);
							if (value) {
								setAddAdjustment(0);
							} else {
								setRemoveAdjustment(0);
							}
						}}
					/>
				</div>
				<p className='slide-over-text'>
					Licenses removed from this group go directly into your account licenses.
				</p>

				{!!isRemoving && (
					<>
						<hr className={'text-gray-400 mt-2 mb-4'} />
						<div className='flex flex-1 items-center'>
							<p className='slide-over-text flex flex-1'>
								{!canRemove && (
									<AppRIIcon icon={'error-warning-fill'} className={'text-error mr-2 text-lg'} />
								)}
								<span>Total licenses to remove</span>
							</p>
							<div className={'w-20'}>
								<AppInput
									name={'wg-remove-adjust-license'}
									disabled={currentLicenses < 3}
									min={1}
									max={assessment.licenses}
									containerClassName={'no-margin'}
									type={'number'}
									value={removeAdjustment}
									onChange={(e) => setRemoveAdjustment(Math.min(e.target.value, currentLicenses))}
									errors={!canRemove ? { 'wg-remove-adjust-license': 'error' } : {}}
								/>
							</div>
						</div>
					</>
				)}

				<div className='mb-4'>
					<hr className={`text-gray-400 ${!canRemove ? 'mt-4' : 'my-4'}`} />
					{isRemoving && !canRemove && (
						<span className={`app-input-error-message visible mt-1`}>
							{parseInt(assessment.licenses) - parseInt(assessment.num_scores) < removeAdjustment
								? 'There are not enough unused licenses in this account to be removed. Please choose a different amount.'
								: 'You can\'t remove licenses from this group, the minimum number of licenses per group is 2. If you want to remove all unused licenses you can use "Close this assessment" button.'}
						</span>
					)}
				</div>

				<div className='flex flex-1 mt-5'>
					<AppButton
						disabled={isRemoving ? removeAdjustment === 0 || !canRemove : addAdjustment === 0}
						onClick={_addLicenses}
						loading={loading}
						text={'Confirm'}
						className={'btn btn-primary flex flex-1 mr-2'}
						id={'wg-adjust-license-confirm-btn'}
					/>
					<AppButton
						type={'button'}
						className={'btn btn-secondary flex flex-1'}
						onClick={() => closeModal({})}
						loading={loading}
						text={'Cancel'}
						id={'wg-adjust-license-cancel-btn'}
					/>
				</div>
			</div>
		</AppSlideOver>
	);
};

export default AddLicensesModal;
