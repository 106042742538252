import React from 'react';
import { navigate } from 'gatsby';

import AppTooltip from '../AppTooltip';
import AppProgressBar from '../AppProgressBar';
import AddLicensesModal from './AddLicensesModal';
import { formatDate } from '../../services/UtilityService';
import CloseAssessmentModal from './CloseAssessmentModal';
import EditAssessmentModal from './EditAssessmentModal';
import AppIcon from '../AppIcon';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';
import { useUser } from '../../hooks/UserProvider';

const DetailsHeader = ({ assessment, onEdit, hasIncompleteAssessments, roster, onRosterUpdate, setChangeNameItem }) => {
	const { hasPermission } = useUser();
	const [isLicenseModalOpen, setIsLicenseModalOpen] = React.useState(false);
	const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
	const isCompleted = !!assessment.complete;
	const isGroup = assessment.type === 'group';
	const isStandard = assessment.assessment_mode === AssessmentModeEnum.STANDARD;
	const isStudent = assessment.assessment_mode === AssessmentModeEnum.STUDENT;
	const hasStandardMode = assessment.roster_mode && assessment.roster_mode.includes(AssessmentModeEnum.STANDARD);
	const hasStudentMode = assessment.roster_mode && assessment.roster_mode.includes(AssessmentModeEnum.STUDENT);
	const isMixed =
		(hasStandardMode && hasStudentMode) || (isStudent && hasStandardMode) || (isStandard && hasStudentMode);
	const handleOnBackClick = () => navigate(-1);
	const handleOnBackKeyboard = (e) => {
		switch (e.key) {
			case "Enter":
			case " ":
				handleOnBackClick();
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};
	const handleKeyDownEditModal = (e) => {
		switch (e.key) {
			case "Enter":
			case " ":
				setIsEditModalOpen(true);
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};
	const handleKeyDownAdjustLicense = (e) => {
		switch (e.key) {
			case "Enter":
			case " ":
				setIsLicenseModalOpen(true);
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};
	const handleKeyDownCloseAssessment = (e) => {
		switch (e.key) {
			case "Enter":
			case " ":
				setIsCloseModalOpen(true);
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<div className={'mt-8'}>
			<span
				id={'details-header-back-button'}
				className={'cursor-pointer text-secondary underline font-bold text-lg'}
				onClick={handleOnBackClick}
				onKeyDown={handleOnBackKeyboard}
				role="button"
				tabIndex={0}
			>
				&#60; back
			</span>
			<h1
				id={'details-header-name'}
				className={
					'text-primary text-2xl md:text-5xl font-medium md:font-bold text-center md:text-left ellipse-text'
				}
			>
				{isGroup ? assessment.group : `${assessment.fname} ${assessment.lname}`}
			</h1>
			<div className='flex flex-col md:flex-row pt-2'>
				<div className='flex flex-1 flex-col items-center md:items-start ellipse-text'>
					{!!assessment.company && (
						<p className='font-thin text-primary py-1 text-xl ellipse-text'>
							{isStudent ? 'School name: ' : isStandard ? 'Company name: ' : ''}
							<span className='leading-6 font-medium'>{assessment.company}</span>
						</p>
					)}
					<p className='font-thin text-primary py-1 text-lg md:text-xl'>
						Type:{' '}
						<span className='leading-6 font-medium'>
							{isMixed
								? `Mixed Group Configured as ${isStudent ? 'Student' : 'Standard'}`
								: isGroup
									? isStudent
										? 'Student Group Assessment'
										: 'Group Assessment'
									: isStudent
										? 'Individual Student Assessment'
										: 'Individual Assessment'}
						</span>
					</p>
					<p className='font-thin text-primary py-1 text-lg md:text-xl'>
						{assessment.shared_from ? 'Shared on' : 'Created on'}
						{':'}
						<span className='leading-6 font-medium'>
							{formatDate(assessment.created, ' MMMM DD, YYYY hh:mm A')}
						</span>
					</p>
					{!assessment.send && (
						<p className='font-thin text-primary text-xl'>
							{isGroup ? 'Reports withheld' : 'Report withheld'}
						</p>
					)}
					{assessment.shared_from && !isGroup && (
						<p className='font-thin text-primary text-center text-lg md:text-xl'>
							Shared by: <span className='leading-6 font-medium'>{assessment.shared_from}</span>
						</p>
					)}
					{assessment.cc_emails && (
						<p className='font-thin text-primary text-xl'>
							Additional contacts:{' '}
							<span className='leading-6 font-medium'>{assessment.cc_emails.replaceAll(',', ', ')}</span>
						</p>
					)}
				</div>
				<div className='flex flex-col items-center md:items-end'>
					{hasPermission('WG_ASSESSMENT.PUT') && <div className={'my-1'}>
						<span
							className={
								'cursor-pointer underline text-secondary hover:text-darkenSecondary font-medium md:font-bold'
							}
							onClick={() => {
								setIsEditModalOpen(true);
								roster && setChangeNameItem(roster);
							}}
							id={'wg-clientpage-edit-info-link'}
							role="button"
							tabIndex={0}
							onKeyDown={handleKeyDownEditModal}
						>
							Edit information
						</span>
					</div>}

					{hasPermission('WG_LICENSE.POST.ADD') && (isGroup && (
						<div className={'flex flex-row items-center content-center my-1'}>
							<span
								className={
									'cursor-pointer underline text-secondary hover:text-darkenSecondary font-medium md:font-bold'
								}
								onClick={() => setIsLicenseModalOpen(true)}
								id={'wg-clientpage-add-licences-link'}
								role="button"
								tabIndex={0}
								onKeyDown={handleKeyDownAdjustLicense}
							>
								Adjust Licenses
							</span>
							<AppTooltip
								placement={'left'}
								type={'small'}
								content={() => {
									return <p className={'text-white text-tiny'}>Adjust your group licenses and<br/>view licenses count</p>;
								}}
							>
								<AppIcon className={'text-primary'} icon={'info-circled'} />
							</AppTooltip>
						</div>
					))}

					{isGroup && isCompleted && (
						<div>
							<p className={'text-primary text-xl'}>
								<span className='font-bold'>Assessment Status:</span>
								{' Complete'}
							</p>
						</div>
					)}

					{hasPermission('WG_ASSESSMENT.POST.CLOSE') && (!isCompleted && (
						<div className={'flex flex-row my-1'}>
							<span
								className={
									'cursor-pointer underline text-secondary hover:text-darkenSecondary font-medium md:font-bold'
								}
								onClick={() => setIsCloseModalOpen(true)}
								id={'wg-clientpage-close-assessment-link'}

								role="button"
								tabIndex={0}
								onKeyDown={handleKeyDownCloseAssessment}
							>
								Close this assessment
							</span>
							<AppTooltip
								placement={'top'}
								type={'small'}
								content={() => {
									return (
										<p className={'text-white text-tiny text-center w-40'}>
											This will put any unused licenses back to your account
										</p>
									);
								}}
							>
								<AppIcon className={'text-primary'} icon={'info-circled'} />
							</AppTooltip>
						</div>
					))}
					{isGroup && (
						<div>
							<div className={'my-1 w-56'}>
								<AppProgressBar
									progress={
										assessment.licenses === Number(assessment.num_scores)
											? 100
											: (Number(assessment.num_scores) * 100) / assessment.licenses
									}
								/>
							</div>
							<div className={'my-1'}>
								<p className={'text-primary text-center'}>
									<span className={'font-bold'}>{assessment.num_scores}</span>
									<span> of </span>
									<span className={'font-bold'}>{assessment.licenses}</span>
									<span> complete.</span>
								</p>
							</div>
						</div>
					)}
					{!isGroup && (
						<div>
							<p className={'text-primary text-xl'}>
								<span className='font-bold'>Assessment Status:</span>{' '}
								{isCompleted ? 'Complete' : 'Incomplete'}
							</p>
						</div>
					)}
				</div>
			</div>
			<AddLicensesModal
				assessment={assessment}
				isOpen={isLicenseModalOpen}
				closeModal={({ saved, assessment }) => {
					setIsLicenseModalOpen(false);
					if (saved) {
						onEdit(assessment);
					}
				}}
			/>
			<CloseAssessmentModal
				isOpen={isCloseModalOpen}
				assessment={assessment}
				closeModal={({ saved, assessment }) => {
					setIsCloseModalOpen(false);
					if (saved) {
						if (assessment) {
							onEdit(assessment);
						} else {
							navigate('/client/', { replace: true });
						}
					}
				}}
				hasIncompleteAssessments={hasIncompleteAssessments}
			/>
			<EditAssessmentModal
				isOpen={isEditModalOpen}
				assessment={assessment}
				closeModal={({ saved, assessment }) => {
					setIsEditModalOpen(false);
					if (saved) {
						onEdit(assessment);
					}
				}}
				roster={roster}
				onRosterUpdate={onRosterUpdate}
			/>
		</div>
	);
};

export default DetailsHeader;
